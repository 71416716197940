<template>
  <div class="flex flex-col w-full pt-20 h-screen">
    <Header is-checkout />

    <main class="flex-1">
      <slot />
    </main>

    <Footer is-checkout />
  </div>
</template>
